import { useCallback } from "react";
import Helmet from "react-helmet-async";

import StandardButton from "components/Buttons/StandardButton";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const META = [{ name: "robots", content: "noindex,nofollow" }];

const baseStyles = {
  container: {
    ...gStyles.gradientBackground,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingTop: "7em",
    paddingBottom: "7em",
    minHeight: 400,
  },
  box: {
    padding: "20px",
    textAlign: "center",
    color: "#fff",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    marginBottom: "1.25rem",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3rem",
  },
  buttonContainer: {
    display: "inline-block",
    width: 150,
    margin: "0 0.5rem",
  },
};

function Error404(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const handleHomepageClick = useCallback(() => {
    if (window && window.location) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div className={css(styles.container)}>
      <Helmet meta={META} />
      <div className={css(styles.box)}>
        <div className={css(styles.title)}>
          {"Sorry, this page doesn't exist."}
        </div>
        <div>
          If you think this is incorrect, please contact support at
          contact@podchaser.com.
        </div>
        <div className={css(styles.buttons)}>
          <div className={css(styles.buttonContainer)}>
            <StandardButton
              label="Open Homepage"
              onClick={handleHomepageClick}
              variation="pink"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error404;
